<template>
  <div>
    <div class="card">
      <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
        <div class="anan-tabs__nav">
          <div
            class="anan-tabs__nav-warp px-2"
            style="margin-top: 5px;"
          >
            <div
              class="anan-tabs__nav-tabs"
              style="transform: translateX(0px)"
            >
              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === '0' }"
                style="white-space: normal"
                @click="getData(status = 0, search_val), isActive = '0'"
              >
                <span> {{ $t('all') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === '1' }"
                style="white-space: normal"
                @click="getData(status = 1, search_val), isActive = '1'"
              >
                <span> {{ $t('noOwner') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === '2' }"
                style="white-space: normal"
                @click="getData(status = 2, search_val), isActive = '2'"
              >
                <span> {{ $t('cancel') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === '3' }"
                style="white-space: normal"
                @click="getData(status = 3, search_val), isActive = '3'"
              >
                <span> {{ $t('pendingReview') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === '4' }"
                style="white-space: normal"
                @click="getData(status = 4, search_val), isActive = '4'"
              >
                <span> {{ $t('operationCompleted') }} </span>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="d-lg-flex justify-content-between align-items-center px-2 mt-2">
        <h3 class="font-weight-bolder">
          {{ $t('ownerlessGoods') }}
        </h3>

        <div class="d-flex align-items-center">
          <div class="anan-input__inner anan-input__inner--normal">
            <input
              v-model="search_val"
              type="text"
              :placeholder="$t('search')"
              class="anan-input__input"
              @keyup.enter="getData(status,search_val)"
            >
            <div class="anan-input__suffix">
              <button
                type="button"
                class="anan-button anan-button--normal anan-button--primary"
                @click="getData(status,search_val)"
              >
                <i class="fal fa-search" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- <router-link
        :to="{name: 'admin-goodsconfirm-create'}"
        class="px-2"
      > -->
      <div class="px-2 pt-1">
        <button
          type="button"
          class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
          @click="$router.push({ name: 'admin-goodsconfirm-create' })"
        >
          <span>+ {{ $t('addNewItem') }} </span>
        </button>
      </div>

      <!-- </router-link> -->
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <div class="p-2">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  label="กำลังโหลด..."
                  variant="danger"
                />
              </div>
            </template>
            <template #cell(itemnumber_text)="data">
              <b-link :to="{name: 'admin-goodsconfirm-edit', params: {id: data.item.id}}">
                {{ data.item.itemnumber_text }}
              </b-link>
            </template>

            <template #cell(index)="data">
              {{ perPage * (currentPage - 1) + (data.index + 1) }}
            </template>

            <template #cell(created_at)="data">
              {{ time(data.item.created_at) }}
            </template>

            <template #cell(username)="data">
              <b-link
                v-if="data.item.username"
                :to="{name: 'admin-employee-edit', params: {id: data.item.uid}}"
              >
                {{ data.item.username }}
              </b-link>
              <span
                v-else
                class="badge badge-warning text-black"
              >{{ $t('none') }}</span>
            </template>
            <template #cell(lot_name)="data">
              <b-link :to="{name: 'admin-warehouse-edit', params: {id: data.item.lot_name}}">
                {{ data.item.lot_name }}/{{ data.item.lot_order }}
              </b-link>
            </template>
            <template #cell(postnumber)="data">
              <b-link :to="{name: 'admin-tracking-edit', params: {id: data.item.id_postnumber}}">
                {{ data.item.postnumber }}
              </b-link>
            </template>

            <template #cell(fileimg)="data">
              <span
                v-for="imageOr in data.item.fileadmin"
                :key="imageOr"
                class="preview-image-container mr-1 d-flex justify-content-center"
              >
                <img
                  :src="imageOr"
                  alt="img-document"
                  class="border"
                  height="40"
                  width="50"
                  @click="showPicture(imageOr)"
                >

                <vue-image-lightbox-carousel
                  ref="lightbox"
                  :show="showLightbox"
                  :images="images"
                  :show-caption="false"
                  @close="closeBox()"
                />

              </span>

            </template>
            <template #cell(timeokay)="data">
              {{ data.item.timeokay ? data.item.timeokay: '-' }}

            </template>

            <template #cell(statusconfirm)="data">
              <span
                v-if="data.item.statusconfirm == 0"
                class="badge badge-secondary"
              >
                {{ $t('cancelled') }}
              </span>
              <span
                v-if="data.item.statusconfirm == 1"
                class="badge badge-danger"
              >
                {{ $t('noOwner') }}
              </span>
              <span
                v-if="data.item.statusconfirm == 2"
                class="badge badge-info"
              >
                {{ $t('pendingReview') }}
              </span>
              <span
                v-if="data.item.statusconfirm == 3"
                class="badge badge-success"
              >
                {{ $t('operationCompleted') }}
              </span>
            <!-- <span
              v-if="data.item.status === 'waiting'"
              class="badge badge-primary badge-pill"
            > รอตรวจสอบ </span>

            <span
              v-if="data.item.status === 'success'"
              class="badge badge-success badge-pill"
            > ดำเนินการสำเร็จ </span> -->

            </template>
            <template #cell(active)="data">
              <b-form-checkbox
                v-model="data.item.active"
                :value="true"
                class="custom-control-success"
                name="check-button"
                switch
                inline
                @change="Checkedactive(data.item)"
              />
            </template>

            <template #cell(actions)="data">
              <feather-icon
                icon="Edit3Icon"
                class="mr-1 text-primary cursor-pointer"
                @click="$router.push({ name: 'admin-goodsconfirm-edit', params: { id: data.item._id } })"
              />
            </template>
          </b-table>

          <div class="demo-spacing-0 d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="getData(status, search_val)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-overlay>
    </div>
    <!-- modal -->
    <b-modal
      ref="my-modal"
      hide-footer
      :title="$t('key-34')"
    >
      <img
        :src="pic"
        alt="..."
        class="img-fluid rounded"
      >

      <div class="text-center">
        <button
          type="button"
          class="btn btn-primary mt-2"
          @click="downloadImg"
        >
          Download
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BPagination, BLink, BModal, BTable, BSpinner, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BPagination,
    BLink,
    BModal,
    BTable,
    BSpinner,
    BFormCheckbox,
    BOverlay,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      search: '',
      isActive: '0',
      selectedRadio: 'active',
      optionsRadio: [
        { text: 'All', value: 'all' },
        { text: 'Car', value: 'car' },
        { text: 'Ship', value: 'ship' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 7,
      totalRows: 1,
      items: [],
      pic: null,

      total: 0,
      notpaid: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      search_val: null,
      status: 0,
      showOver: false,

    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'itemnumber_text', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'created_at', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'timeokay', label: this.$t('approvalDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'username', label: this.$t('customer'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'lot_name', label: this.$t('lotSequence'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'postnumber', label: this.$t('parcelCode'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'fileimg', label: this.$t('attachedFile'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'statusconfirm', label: this.$t('status'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'active', label: this.$t('active'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-right',
        },
        {
          key: 'actions', label: this.$t('manage'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  watch: {
    '$route.query.querykey': {
      immediate: true,
      handler() {
        this.handleQueryChange()
      },
    },
  },
  mounted() {
    // this.GetNotify()
    // this.GetOrderAll(0)
    // this.getData(this.status, this.search_val)
    this.handleQueryChange()
  },
  methods: {
    handleQueryChange() {
      const { querykey } = this.$route.query
      if (querykey) {
        if (Number(querykey) === 2) {
          this.isActive = '3'
          this.status = 3
          this.getData(this.status, this.search_val)
        }
      } else {
        this.getData(this.status, this.search_val)
      }
    },
    DeleteType(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$http.delete(`/order/delete/${data._id}`)
        .then(() => {
          this.GetOrderAll()
        })
        .catch(error => console.log(error))
    },
    EditType(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'admin-paymenttochina-edit', params: { id: data._id } })
    },
    Checkedactive(data) {
      this.$http.post('/Goodsconfirm/updata', data)
        .then(() => {
          this.getData()
          this.Success('บันทึกข้อมูลเรียบร้อย')
        })
        .catch(err => {
          this.SwalError(err)
        })
    },
    // Commas(num) {
    //   return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // },
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    // eslint-disable-next-line camelcase
    async getData(type, search_val) {
      this.showOver = true
      try {
        const params = {
          type,
          search_val,
          page: this.currentPage,
          perPage: this.perPage,
        }
        const { data: res } = await this.$http.get('/Goodsconfirm/getdata', { params })
        res.data.map(ele => { ele.fileadmin = [] })
        this.items = res.data
        this.totalRows = res.total
        this.showOver = false
        this.ShowIMG()
        // console.log(res)
      } catch (err) {
        this.SwalError(err)
        this.showOver = false
      }
    },
    ShowIMG() {
      const Id = this.items.map(ele => ele._id)
      const params = {
        id: Id,
      }
      this.$http.get('/Goodsconfirm/GetDataimg', { params })
        .then(response => {
          // console.log(response)
          response.data.forEach((data, index) => {
            this.items[index].fileadmin = data.fileadmin
          })
        })
        .catch(err => console.log(err))
    },
    // Goodsconfirm/GetDataimg
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: picture }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    // GetNotify() {
    //   this.$http
    //     .get('/order/Notify')
    //     .then(response => {
    //       // console.log(response.data)
    //       this.total = response.data.total
    //       this.waitingforcheck = response.data.waitingforcheck
    //       this.waitingforpayment = response.data.waitingforpayment
    //       this.paid = response.data.paid
    //       this.OrderSuccess = response.data.OrderSuccess
    //       this.completetrack = response.data.completetrack
    //       this.completewarehouse = response.data.completewarehouse
    //       this.cancel = response.data.cancel
    //     })
    //     .catch(error => console.log(error))
    // },
    // GetOrderAll(type) {
    //   const params = {
    //     page: this.currentPage,
    //     perPage: this.perPage,
    //     type,
    //   }
    //   this.$http
    //     .get('/order/orderAll', { params })
    //     .then(response => {
    //       // console.log(response.data)
    //       this.items = response.data.data
    //       // console.log(this.items)
    //       // this.totalRows = this.items.length
    //       this.totalRows = response.data.total
    //     })
    //     .catch(error => console.log(error))
    // },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    updateKeyword(id, note) {
      // console.log('Item ID:', id)
      // console.log('note:', note)
      const params = {
        id,
        note,
      }
      // console.log(params)
      this.$http
        .post('/order/updateNote', params)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
  },
}
</script>

      <style lang="scss" scoped></style>
